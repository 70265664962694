const DISPLAY_NAME_SESSION_KEY = 'displayName';
const EMAIL_OPT_IN_SESSION_KEY = 'emailOptIn';
const SCHOOL_ID_SESSION_KEY = 'schoolId';
const SCHOOL_ZIP_SESSION_KEY = 'schoolZip';
const SCHOOL_NAME_SESSION_KEY = 'schoolName';

export {
  DISPLAY_NAME_SESSION_KEY,
  EMAIL_OPT_IN_SESSION_KEY,
  SCHOOL_ID_SESSION_KEY,
  SCHOOL_ZIP_SESSION_KEY,
  SCHOOL_NAME_SESSION_KEY,
};
